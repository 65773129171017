import { htmlSafe, type SafeString } from '@ember/template';
import { service } from '@ember/service';
import Helper from '@ember/component/helper';
import type IntlService from 'ember-intl/services/intl';

type Positional = [number | string | null | undefined];

interface S {
  Args: {
    Positional: Positional;
  };
  Return: string | SafeString;
}

export default class FormatCurrencySuperscriptCents extends Helper<S> {
  @service declare intl: IntlService;

  compute([amount]: Positional) {
    if (!amount) {
      return '';
    }
    if (typeof amount === 'string') {
      amount = parseInt(amount) as number;
    }
    const valueToFormat = amount / 100;
    const formattedNumber = this.intl.formatNumber(valueToFormat, {
      style: 'currency',
      currency: 'USD',
    });

    const [dollars, cents] = formattedNumber.split('.');

    return htmlSafe(`${dollars}<sup>${cents}</sup>`);
  }
}
