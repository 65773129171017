// app/helpers/json-pretty-print.js
// usage {{json-pretty-print someJson}}
// see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#The_space_argument

import { helper } from '@ember/component/helper';

const clsMap = [
  [/^".*:$/, 'red'],
  [/^"/, 'green'],
  [/true|false/, 'blue'],
  [/null/, 'magenta'],
  [/.*/, 'darkorange'],
];

type JSONValue =
  | string
  | number
  | boolean
  | null
  | { [key: string]: JSONValue }
  | JSONValue[]
  | Record<string, unknown>
  | unknown;

export default helper(function jsonPrettyPrint(params: [JSONValue]): string {
  const [json] = params;

  // TODO: Should pass in custom CSS as arg since these are tailwind classes
  return `<pre class="whitespace-pre-wrap break-keep">${JSON.stringify(json, null, '\t')
    .trim()
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      (match) =>
        `<span style="color:${
          // @ts-expect-error: find() is not assignable to type
          clsMap.find(([regex]) => regex.test(match))?.[1] || 'inherit'
        }">${match}</span>`
    )}</pre>`;
});

interface JsonObject {
  [key: string]: JsonValue;
}

type JsonValue = string | number | boolean | null | JsonObject | JsonValue[];

interface Changes {
  [key: string]: [JsonValue, JsonValue];
}

export function compareJsonObjects<T extends JsonObject>(
  obj1: T,
  obj2: T,
  prefix: string = ''
): Changes {
  const changes: Changes = {};

  function addChange(key: string, value1: JsonValue, value2: JsonValue) {
    const fullKey = prefix ? `${prefix}.${key}` : key;
    changes[fullKey] = [value1, value2];
  }

  function compareArrays(arr1: JsonValue[], arr2: JsonValue[], key: string) {
    if (arr1.length !== arr2.length) {
      addChange(key, arr1, arr2);
      return;
    }
    for (let i = 0; i < arr1.length; i++) {
      // @ts-expect-error: This is a runtime check
      if (isObject(arr1[i]) && isObject(arr2[i])) {
        const nestedChanges = compareJsonObjects(
          arr1[i] as JsonObject,
          arr2[i] as JsonObject,
          `${key}[${i}]`
        );
        Object.assign(changes, nestedChanges);
      } else if (arr1[i] !== arr2[i]) {
        // @ts-expect-error: This is a runtime check
        addChange(`${key}[${i}]`, arr1[i], arr2[i]);
      }
    }
  }

  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      if (Object.prototype.hasOwnProperty.call(obj2, key)) {
        // @ts-expect-error: This is a runtime check
        if (isObject(obj1[key]) && isObject(obj2[key])) {
          const nestedChanges = compareJsonObjects(
            obj1[key] as JsonObject,
            obj2[key] as JsonObject,
            prefix ? `${prefix}.${key}` : key
          );
          Object.assign(changes, nestedChanges);
        } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
          compareArrays(
            obj1[key] as JsonValue[],
            obj2[key] as JsonValue[],
            prefix ? `${prefix}.${key}` : key
          );
        } else if (obj1[key] !== obj2[key]) {
          // @ts-expect-error: This is a runtime check
          addChange(key, obj1[key], obj2[key]);
        }
      } else {
        // @ts-expect-error: This is a runtime check
        addChange(key, obj1[key], null);
      }
    }
  }

  for (const key in obj2) {
    if (
      Object.prototype.hasOwnProperty.call(obj2, key) &&
      !Object.prototype.hasOwnProperty.call(obj1, key)
    ) {
      // @ts-expect-error: This is a runtime check
      addChange(key, null, obj2[key]);
    }
  }

  return changes;
}

export function isObject(value: JsonValue): value is JsonObject {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}
