import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import type StoreService from '@ember-data/store';
import type { UploadFile } from 'ember-file-upload';
import type PersonModel from '../models/person.ts';
import type DigitalAssetsService from './digital-assets';

export default class ObservablesService extends Service {
  @service declare store: typeof StoreService;
  @service declare digitalAssets: DigitalAssetsService;

  @action
  async createObservableDocument(
    uploadFile: UploadFile,
    options: { person: PersonModel; batch?: string }
  ): Promise<void> {
    const { person, batch } = options;

    // get API path for observable assets
    const collectionPath = this.store
      .adapterFor('application')
      .urlForCreateRecord('observable-asset');

    // set headers
    const headers = this.store.adapterFor('application')?.headers;
    headers['Content-Type'] = 'application/vnd.api+json';

    // create asset record through API
    const assetResponse = await fetch(`${collectionPath}/s3-signed-url`, {
      headers: headers,
      method: 'POST',
      body: JSON.stringify({ filename: uploadFile.file.name, public_image: false }),
    });

    // read asset response content and upload binary to signed url
    const assetDetails = await assetResponse.json();
    await uploadFile.uploadBinary(assetDetails.url, { method: 'PUT' });

    // create and save observable asset
    const observableAsset = this.digitalAssets.createAssetFromS3Details(
      'observable-asset',
      assetDetails
    );
    await observableAsset.save();

    // create and save observable document
    const observableDocument = this.store.createRecord('observable-document', {
      observableAsset,
      person,
      batch,
    });
    await observableDocument.save();
  }
}
