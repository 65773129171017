import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type UserModel from './user.ts';
import type PersonModel from './person.ts';
import type FinancialInstitutionModel from './financial-institution.ts';
import type LoanModel from './loan.ts';
import type MatchPayment from './match-payment.ts';

type Observation = {
  balance: number;
  providerRegularMonthlyPayment: number;
  'interest-weighted-average': number;
  'interest-balance': number;
  'principal-balance': number;
  'payable-account-number': string;
  'provider-amount-due': number;
  'provider-regular-monthly-payment': number;
  'provider-attempt-at': string;
  pii: {
    name: string;
    email: string;
    zipCode: string;
    phoneNumber: string;
  };
  provider: string;
  action?: string;
};

type AccountSummary = {
  loanCount: number;
  cumulativePrincipal: number;
  minimumMonthlyPayment: number;
  weightedInterest: number;
};

export default class AccountModel extends Model {
  @attr declare status: string;
  @attr declare number: string;
  @attr declare deactivatedOn: string;
  @attr declare isActive: boolean;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;
  @attr declare publicOrdinalValue: number;
  @attr declare observation: Observation;
  @attr declare observationOverrides: Record<string, unknown>;
  // TODO: This is on a link response, but is it a normal attribute?
  @attr declare summary: AccountSummary;
  @attr declare isVerified: boolean;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('financial-institution', { async: false, inverse: null })
  declare financialInstitution: FinancialInstitutionModel;

  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;

  @belongsTo('person', { async: false, inverse: null })
  declare person: PersonModel;

  @hasMany('loan', { async: false, inverse: 'account' })
  declare loans: LoanModel[];

  @hasMany('match-payment', { async: false, inverse: 'account' })
  declare matchPayments: MatchPayment[];

  /**************************
   **  Computed Properties **
   **************************/

  get institutionName() {
    return this.financialInstitution?.name;
  }

  get currency() {
    return 'USD';
  }

  get activeLoans() {
    return this.loans?.filter((e) => e.isActive) || [];
  }

  get loanTotal() {
    return this.observation?.balance || 0;
  }

  get loanInterestAverage() {
    return this.observation?.['interest-weighted-average'] || 0;
  }

  get interestBalance() {
    return this.observation?.['interest-balance'] || 0;
  }

  get principalBalance() {
    return this.observation?.['principal-balance'] || 0;
  }

  get loanMinMonthlyPayment() {
    return this.observation?.['provider-amount-due'] || 0;
  }

  get providerRegularMonthlyPayment() {
    return this.observation?.['provider-regular-monthly-payment'] || 0;
  }

  get providerAttemptAt() {
    return this.observation?.['provider-attempt-at'] || '';
  }

  get inRelink() {
    return this.observation?.['action'] === 'RELINK';
  }

  get repaymentPlans() {
    const filtered = [
      ...new Set(this.activeLoans.map((loan) => loan.repaymentPlan).filter((el) => el)),
    ];
    return filtered.join(', ');
  }

  get repaymentStatuses() {
    const filtered = this.activeLoans.map((loan) => loan.status).filter((el) => el);

    return filtered.join(', ');
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    account: AccountModel;
  }
}
