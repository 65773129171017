import { attr, belongsTo } from '@ember-data/model';
import DigitalAsset from './digital-asset.ts';
import type FinancialInstitutionModel from './financial-institution.ts';
import type PersonModel from './person.ts';
import type ObservableAssetModel from './observable-asset';

export default class ObservableDocumentModel extends DigitalAsset {
  /*************************
   **  Attributes         **
   *************************/
  // unique identifier associated with a batch of documents that were uploaded together
  @attr declare batch: string;
  // serialized data parsed from asset
  // TODO: narrow this type when extracted content is more defined - James 20241001
  @attr declare extractedContent: Record<string, unknown>;

  /*************************
   **  Relationships      **
   *************************/
  @belongsTo('observable-asset', { async: false, inverse: 'observableDocument' })
  declare observableAsset: ObservableAssetModel;

  @belongsTo('person', { async: false, inverse: 'observableDocument' })
  declare person: PersonModel;

  @belongsTo('financial-institution', { async: false, inverse: null })
  declare financialInstitution: FinancialInstitutionModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    observableDocument: ObservableDocumentModel;
  }
}
