import { action } from '@ember/object';
import Service, { service } from '@ember/service';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type { Step } from 'tio-common/components/tio/progress-tracker';
import type { IntlService } from 'ember-intl';
import type { ApplicationApprovalSummary } from 'tio-common/types/tuition-assistance';
import type StoreService from 'tio-common/services/store';
// @ts-expect-error: this doesn't exist yet
import type SessionContextService from './session-context.ts';
import type EmployeeModel from '../models/employee.ts';
import { tracked } from '@glimmer/tracking';

/**
 * A service for building progress (bubbles) of applications.
 *
 * @memberof services
 */
const formatTimestampToMMDDYYYY = (timestamp: string) => {
  const date = new Date(timestamp);

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

const logDate = (model: TasApplicationModel | TasProgramInstanceModel, eventString: string) => {
  const timeStamp = model?.transitionLog.find((log) => log.event === eventString)?.timestamp;
  return formatTimestampToMMDDYYYY(timeStamp || '');
};

const getBy = (application: TasApplicationModel, event: string) => {
  const log = application.transitionLog.find((log) => log.event === event);
  return log?.reason?.by?.name;
};

export default class ProgressBuilderService extends Service {
  @service declare intl: IntlService;
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  @tracked employees: EmployeeModel[] = this.adminEmployees;

  get adminEmployees() {
    return this.store.peekAll('employee');
  }

  stepLabel(key: string): string {
    const translationKey = `common.progress_tracker.${key}`;
    return this.intl.t(translationKey);
  }

  isStepCompleted(application: TasApplicationModel, log: string): boolean {
    return application.transitionLog.some((logEntry) => logEntry.from === log);
  }

  isInProgress(state: string, possibleStates: string[]): boolean {
    return possibleStates.includes(state);
  }

  actionNeeded(state: string, actionNeededStates: string[]): boolean {
    return actionNeededStates.includes(state);
  }

  startApplicationStep(application: TasApplicationModel): Step {
    return {
      title: this.stepLabel('start_application'),
      completed: application ? true : false,
      details: [
        {
          componentName: 'DetailWithIcon',
          props: {
            icon: 'calendar_today',
            detail: `Application Started ${formatTimestampToMMDDYYYY(application.createdAt)} `,
          },
        },
        {
          componentName: 'FullHistory',
        },
      ],
    } as unknown as Step;
  }

  submitForApprovalStep(application: TasApplicationModel): Step {
    const template = application.tasProgramInstance.tasProgramTemplate;
    if (template.courseApprovalRequired) {
      return {
        title: this.stepLabel('submit_for_approval'),
        completed: this.isStepCompleted(application, 'TAS.ApplicationState.DEFINE_COURSES'),
        inProgress: this.isInProgress(application.state, ['TAS.ApplicationState.DEFINE_COURSES']),
        details: [
          {
            componentName: 'DetailWithIcon',
            props: {
              icon: 'person',
              detail: `Submitted by ${getBy(application, 'request_courses_approval')}`,
            },
          },
          {
            componentName: 'FullHistory',
          },
        ],
      } as unknown as Step;
    }
    return {} as Step;
  }

  applicationApprovalStep(application: TasApplicationModel): Step {
    const template = application.tasProgramInstance.tasProgramTemplate;
    if (template.courseApprovalRequired) {
      return {
        title: this.stepLabel('application_approval'),
        completed: this.isStepCompleted(
          application,
          'TAS.ApplicationState.PENDING_COURSES_APPROVAL'
        ),
        inProgress: this.isInProgress(application.state, [
          'TAS.ApplicationState.DEFINE_COURSES',
          'TAS.ApplicationState.PENDING_COURSES_APPROVAL',
        ]),
        actionNeeded: this.actionNeeded(application.state, [
          'TAS.ApplicationState.COURSES_NOT_APPROVED',
          'TAS.ApplicationState.COURSES_REJECTED',
        ]),
        details: [
          {
            componentName: 'ApproverDetail',
            props: {
              icon: 'person',
              application: application,
              stepToApprove: 'course_approval',
            },
          },
          {
            componentName: 'FullHistory',
          },
        ],
      } as unknown as Step;
    }
    return {} as Step;
  }

  inProgressStep(application: TasApplicationModel): Step {
    return {
      title: this.stepLabel('in_progress'),
      completed: this.isStepCompleted(application, 'TAS.ApplicationState.ATTEND'),
      inProgress: this.isInProgress(application.state, ['TAS.ApplicationState.ATTEND']),
    } as unknown as Step;
  }

  submitEvidenceStep(application: TasApplicationModel): Step {
    const submissionDetails = () => {
      const submitter = getBy(application, 'request_evidence_approval');
      if (submitter) {
        return `Submitted by ${submitter}`;
      } else {
        return 'Evidence not submitted';
      }
    };

    return {
      title: this.stepLabel('submit_evidence'),
      completed: this.isStepCompleted(application, 'TAS.ApplicationState.ATTEND'),
      details: [
        {
          componentName: 'DetailWithIcon',
          props: {
            icon: 'person',
            detail: submissionDetails(),
          },
        },
        {
          componentName: 'FullHistory',
        },
      ],
    } as unknown as Step;
  }

  evidenceApprovalStep(application: TasApplicationModel): Step {
    const hasEvidence = () => {
      if (application.applicationHasSubmittedForEvidenceApproval) {
        return {
          componentName: 'DetailWithIcon',
          props: {
            icon: 'person',
            detail: `Submitted by ${getBy(application, 'request_evidence_approval')}`,
          },
        };
      } else {
        return {
          componentName: 'DetailWithIcon',
          props: {
            icon: 'description',
            detail: 'Grades or receipts not submitted',
          },
        };
      }
    };

    const evidenceApproved = () => {
      if (application.state === 'TAS.ApplicationState.EVIDENCE_REJECTED') {
        return {
          componentName: 'DetailWithIcon',
          props: {
            icon: 'warning',
            iconBgColor: 'bg-red-700',
            detail: `Evidence rejected on ${logDate(application, 'reject_evidence')} by ${getBy(application, 'reject_evidence')}`,
          },
        };
      } else {
        return {
          componentName: 'ApproverDetail',
          props: {
            icon: 'people',
            application: application,
            stepToApprove: 'evidence_approval',
          },
        };
      }
    };

    if (application.tasProgramInstance.tasProgramTemplate.evidenceApprovalRequired) {
      return {
        title: this.stepLabel('evidence_approval'),
        completed: this.isStepCompleted(application, 'TAS.ApplicationState.EVIDENCE_APPROVED'),
        inProgress: this.isInProgress(application.state, [
          'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL',
        ]),
        actionNeeded: this.actionNeeded(application.state, [
          'TAS.ApplicationState.EVIDENCE_NOT_APPROVED',
          'TAS.ApplicationState.EVIDENCE_REJECTED',
        ]),
        details: [
          hasEvidence(),
          evidenceApproved(),
          {
            componentName: 'FullHistory',
          },
        ],
      } as unknown as Step;
    }
    return {} as Step;
  }

  completedStep(application: TasApplicationModel): Step {
    const complete = () => {
      if (application.state === 'TAS.ApplicationState.FULFILLED') {
        return {
          componentName: 'DetailWithIcon',
          props: {
            icon: 'check',
            iconBgColor: 'bg-green-600',
            detail: `Completed on ${logDate(application, 'fulfill')}`,
          },
        };
      } else {
        return {
          componentName: 'DetailWithIcon',
          props: {
            icon: 'calendar_today',
            detail: `Not yet completed.`,
          },
        };
      }
    };

    return {
      title: this.stepLabel('completed'),
      completed: application.state === 'TAS.ApplicationState.FULFILLED',
      actionNeeded: application.paymentState === 'TAS.ApplicationPaymentState.REFUND_REQUESTED',
      inProgress: this.isInProgress(application.state, ['TAS.ApplicationState.EVIDENCE_APPROVED']),
      details: [
        complete(),
        {
          componentName: 'FullHistory',
        },
      ],
    } as unknown as Step;
  }

  @action
  applicationStepList(application: TasApplicationModel): Step[] {
    return [
      this.startApplicationStep(application),
      this.submitForApprovalStep(application),
      this.applicationApprovalStep(application),
      this.inProgressStep(application),
      this.submitEvidenceStep(application),
      this.evidenceApprovalStep(application),
      this.completedStep(application),
    ];
  }

  @action
  applicationProcess(application?: TasApplicationModel): Step[] {
    if (!application) {
      return [
        { title: this.intl.t(`tuition_assistance.program_steps.start_application`) },
        { title: this.intl.t(`tuition_assistance.program_steps.submit_for_evidence_approval`) },
        {
          title: this.intl.t(`tuition_assistance.program_steps.evidence_approval`),
        },
        { title: this.intl.t(`tuition_assistance.program_steps.complete`) },
      ];
    } else {
      return [
        this.startApplicationStep(application),
        this.submitEvidenceStep(application),
        this.evidenceApprovalStep(application),
        this.completedStep(application),
      ];
    }
  }

  @action
  fetchApprovers() {
    const id = this.sessionContext.currentEmployee.company.id;
    this.store
      .query('employee', {
        filter: {
          company_id: id,
        },
      })
      .then((employees: EmployeeModel[]) => {
        this.employees = employees;
      });
  }

  @action
  getApproversForStep(application: TasApplicationModel, step: string) {
    const approvalQueue = (step: string): ApplicationApprovalSummary => {
      switch (step) {
        case 'course_approval':
          return application.coursesApproval;
        case 'evidence_approval':
          return application.evidenceApproval;
        default:
          return {
            affirmed: [],
            current_reviewers: [],
            possible_reviewers: [],
            required: [],
            approved: false,
          };
      }
    };

    const affirmedApprovers = () => {
      const reviewers = [];
      for (const reviewer of approvalQueue(step).affirmed) {
        if (reviewer.dot === 'TAS.Approver.TIO_APPROVER') {
          reviewers.push({ firstName: 'TIO', lastName: 'Approver' });
        } else {
          const employee = this.employees.find((employee) => employee.id == reviewer.employee_id);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          employee && reviewers.push(employee);
        }
      }
      return reviewers.filter(
        (obj1, i, arr) =>
          arr.findIndex((obj2) =>
            // @ts-expect-error: key is a string
            ['firstName', 'lastName'].every((key) => obj2[key] === obj1[key])
          ) === i
      );
    };

    const currentApprovers = () => {
      const reviewers = [];
      for (const reviewer of approvalQueue(step).current_reviewers) {
        if (reviewer.dot === 'TAS.Approver.TIO_APPROVER') {
          reviewers.push({ firstName: 'TIO', lastName: 'Approver' });
        } else {
          const employee = this.employees.find((employee) => employee.id == reviewer.employee_id);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          employee && reviewers.push(employee);
        }
      }
      return reviewers.filter(
        (obj1, i, arr) =>
          arr.findIndex((obj2) =>
            // @ts-expect-error: key is a string
            ['firstName', 'lastName'].every((key) => obj2[key] === obj1[key])
          ) === i
      );
    };

    const possibleApprovers = () => {
      const reviewers = [];
      for (const reviewer of approvalQueue(step).possible_reviewers) {
        if (reviewer.dot === 'TAS.Approver.TIO_APPROVER') {
          reviewers.push({ firstName: 'TIO', lastName: 'Approver' });
        } else {
          const employee = this.employees.find((employee) => employee.id == reviewer.employee_id);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          employee && reviewers.push(employee);
        }
      }
      return reviewers.filter(
        (obj1, i, arr) =>
          arr.findIndex((obj2) =>
            // @ts-expect-error: key is a string
            ['firstName', 'lastName'].every((key) => obj2[key] === obj1[key])
          ) === i
      );
    };

    return {
      possibleApprovers: possibleApprovers(),
      affirmedApprovers: affirmedApprovers(),
      currentApprovers: currentApprovers(),
    };
  }
}
